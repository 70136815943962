//choiceObject.js文件
import Vue from "vue";
import SetFile from "./index.vue";

const $app = Vue.extend(SetFile);

const $SetFile = new $app().$mount(document.createElement("div"));

document.body.appendChild($SetFile.$el);

export default {
  install(vm) {
    vm.prototype.$SetFile = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $SetFile[key] = params[key];
        });
        $SetFile.visible = true;
      },
      hide: () => {
        $SetFile.visible = false;
      },
    };
  },
};
