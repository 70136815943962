/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-10-30 09:38:26
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-11-01 14:56:09
 * @FilePath: \product-yun-business-view\src\router\config\product-yun-report-view.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue"
// import productYunResourceView from "product-yun-resource-view";
import productYunreportView from "product-yun-report-view";
// import productYunreportView from "../../../../product-yun-report-view";
Vue.use(productYunreportView);

import { reportChange,reportUpload,reportView,reportManagement,reportSignature,reportPublicity,reportSending,reportLibrary } from "product-yun-report-view"
// import { reportChange, reportUpload, reportView, reportManagement, reportSignature, reportPublicity, reportSending, reportLibrary } from "../../../../product-yun-report-view"


/** 资源 */
const routes = [
	// 实验室资质S
	// {
	//     path: "/reportView",
	//     name: "reportView",
	//     component: reportView,
	//     meta: {
	//         title: "报告",
	//         groupName: "资源",
	//         icon: "HomePage",
	//     },
	// },
	//   {
	// 	path: "/reportManagement",
	// 	name: "reportManagement",
	// 	component: reportManagement,
	// 	meta: {
	// 		title: "报告管理",
	// 		groupName: '报告',
	// 		icon: "ReportManagement",
	// 	},
	// },
	//   {
	// 	path: "/reportPublicity",
	// 	name: "reportPublicity",
	// 	component: reportPublicity,
	// 	meta: {
	// 		title: "报告公示",
	//           groupName: '报告',
	// 		icon: "ReportPublicity",
	// 	},
	// },
	{
		path: "/reportUpload",
		name: "reportUpload",
		component: reportUpload,
		meta: {
			title: "报告上传",
			groupName: '报告',
			icon: "ReportManagement",
		},
	},
	{
		path: "/reportSignature",
		name: "reportSignature",
		component: reportSignature,
		meta: {
			title: "报告签章",
			groupName: '报告',
			icon: "ReportSignature",
		},
	},
	{
		path: "/reportChange",
		name: "reportChange",
		component: reportChange,
		meta: {
			title: "报告变更",
			groupName: '报告',
			icon: "ReportSignature",
		},
	},
	{
		path: "/reportSending",
		name: "reportSending",
		component: reportSending,
		meta: {
			title: "报告发送",
			groupName: '报告',
			icon: "ReportSending",
		},
	},
	{
		path: "/reportLibrary",
		name: "reportLibrary",
		component: reportLibrary,
		meta: {
			title: "报告库",
			groupName: '报告',
			icon: "ReportLibrary",
		},
	},
]

export default routes
