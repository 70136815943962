import Vue from "vue"
// import productYunResourceView from "product-yun-resource-view";
import productYunResourceView from "product-yun-resource-view";
Vue.use(productYunResourceView);

// import { LaboratoryQualification, TestObject, TestPersonnel, TestEquipment, TestStandard, TestFeeStandard, TestDurationStandard, TestScheme } from "product-yun-resource-view";
import { LaboratoryQualification, TestObject, TestPersonnel, TestEquipment, TestStandard, TestFeeStandard, TestDurationStandard,Inspector,inspectorDatail, TestScheme } from "product-yun-resource-view"


/** 资源 */
const routes = [
    // 实验室资质
    {
        path: "/LaboratoryQualification",
        name: "LaboratoryQualification",
        component: LaboratoryQualification,
        meta: {
            title: "实验室资质",
            groupName: "资源",
            icon: "LabQualification",
        },
    },
    {
        path: "/Inspector",
        name: "Inspector",
        component: Inspector,
        meta: {
            title: "检测机构",
            groupName: "资源",
            icon: "Inspector",
        },
    },
    {
        path: "/inspectorDatail",
        name: "inspectorDatail",
        component: inspectorDatail,
        meta: {
            hideMenu: true,
			currentActiveMenu: '/Inspector'
        },
    },
    // 检测对象
    {
        path: "/TestObject",
        name: "TestObject",
        component: TestObject,
        meta: {
            title: "检测对象",
            groupName: "资源",
            icon: "objectManage",
        },
    },
    // 检测人员
    {
        path: "/TestPersonnel",
        name: "TestPersonnel",
        component: TestPersonnel,
        meta: {
            title: "检测人员",
            groupName: "资源",
            icon: "Manage",
        },
    },
    // 检测设备
    {
        path: "/TestEquipment",
        name: "TestEquipment",
        component: TestEquipment,
        meta: {
            title: "检测设备",
            groupName: "资源",
            icon: "equipmentPage",
        },
    },
    // 检测标准
    {
        path: "/TestStandard",
        name: "TestStandard",
        component: TestStandard,
        meta: {
            title: "检测标准",
            groupName: "资源",
            icon: "Standard",
        },
    },
    // 检测费用标准
    {
        path: "/TestFeeStandard",
        name: "TestFeeStandard",
        component: TestFeeStandard,
        meta: {
            title: "检测费用标准",
            groupName: "资源",
            icon: "TestFeeStandard",
        },
    },
    // 检测时长标准
    {
        path: "/TestDurationStandard",
        name: "TestDurationStandard",
        component: TestDurationStandard,
        meta: {
            title: "检测时长标准",
            groupName: "资源",
            icon: "TestDurationStandard",
        },
    },
    // 检测方案
    {
        path: "/TestScheme",
        name: "TestScheme",
        component: TestScheme,
        meta: {
            title: "检测方案",
            groupName: "资源",
            icon: "Option",
        },
    },
]

export default routes
