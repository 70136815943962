// import {
//     filter,
//     filterCheck,
//     sorterDesc,
//     sorterAsc
// } from '@/core/img';
import { Message } from 'element-ui';
import store from '@/store/index';
import api from '@/api/api';
import { getUuid, downloadFile, deepClone, toFixed } from '@/utils/utils';
import { isElectron, ipcRenderer } from './Electron';
import { getLocalToken } from '@/core/token';
import MixinJs from './Electron/Mixin.js';
export default {
    mixins: [MixinJs],
    data() {
        return {
            _loading: false,
            _loadingInstance: null,
        }
    },
    mounted() {
    },
    computed: {
        dataDictionary(){
            return this.$store.state.dataDictionary;
        },
        tenantId(){
            return this.$store.state.userInfo.tenantId;
        },
        userInfo(){
            return this.$store.state.userInfo;
        },
        isAdmin(){
            return this.$store.state.userInfo.isAdmin === '01'|| this.$store.state.userInfo.isAdmin==="02";
        },
        currentUserId(){
            return this.$store.state.userInfo.id;
        },
        documentTitle(){
            return this.$store.state.documentTitle;
        }
    },
    methods: {
        deepClone,
        toFixed,
        getUuid(){
            return getUuid(32, 36);
        },
        confirmPromise(value, icon, warning) {
            return new Promise((resolve, reject) => {
                const h = this.$createElement;
                this.$msgbox({
                    title: "操作确认",
                    message: h("p", null, [
                        h("span", null, "您确定要 "),
                        h("span", {
                            style: "color: #00796a"
                        }, value || "删除"),
                        h("span", null, " 吗？"),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    customClass: "delMessageClass",
                    type: "warning",
                    iconClass: icon || "el-icon-delete-solid",
                }).then(() => {
                    resolve(true);
                }).catch(() => {
					Message({
						type: 'warning',
						message: `已取消${value || "删除"}`
					});
                    resolve(false);
                })
            })
        },
        downloadFile(fileName, blob, options){
            downloadFile(fileName, blob, options);
        },
        generalVisibilityChange(event, item, key) {
            let ev = event.target;
            let ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
            let content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
            // console.log('visibilityChange', event, ev_weight, content_weight);
            if (ev_weight > content_weight) {
                // 实际宽度 > 可视宽度  文字溢出
                item[key] = false;
                this.$forceUpdate();
            } else {
                // 否则为不溢出
                item[key] = true;
                this.$forceUpdate();
            }
            // console.log('visibilityChange', ev, ev_weight, content_weight, item, key, item[key]);
        },
        verifyConfirm(params){
            return new Promise(resolve => {
                this.$verifyConfirm.show({
                    successCallback: () => {
                        console.log('verifyConfirm-验证成功');
                        resolve(true);
                    },
                    ... (params || {})
                });
            });
        },
        setLoading () {
            this._loading = true;
            this._loadingInstance = this.$loading({
                background: 'transparent',
                spinner: ''
            });
        },
        closeLoading () {
            this._loading = false;
            this._loadingInstance && this._loadingInstance.close();
        },
    }
}