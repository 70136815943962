//choiceObject.js文件
import Vue from "vue";
import choiceObject from "./index.vue";

const $app = Vue.extend(choiceObject);

const $choiceObject = new $app().$mount(document.createElement("div"));

document.body.appendChild($choiceObject.$el);

export default {
  install(vm) {
    vm.prototype.$choiceObject = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $choiceObject[key] = params[key];
        });
        $choiceObject.visible = true;
      },
      hide: () => {
        $choiceObject.visible = false;
      },
    };
  },
};
