import "@/core/view.js"
import { getSortedRoutesByGroup } from "./generate-routes"
/** 首页 */
const Home = () => import("@/views/Home")

import configRoutes from "./config/index"

/** layout固定路由表 */
const constantLayoutRoutes = [
	// {
    //     path: "/",
    //     redirect: "/entrustHome",
    //     // name: "LaboratoryQualification",
    //     // component: LaboratoryQualification,
    //     // meta: {
    //     // 	title: "实验室资质",
    //     // 	groupName: '资源',
    //     // 	icon: "HomePage",
    //     // },
    // },
    {
        path: "/",
        // redirect: "/reportLibrary",
        name: "home",
        component: Home,
        meta: {
        	title: "首页",
        	groupName: '工作台',
        	icon: "HomePage",
        },
    },
 
    ...configRoutes,
]

const routes = []

const addRoute = route => {}

const menus = getSortedRoutesByGroup(
    constantLayoutRoutes.filter(item => {
        return typeof item.component === "undefined" || !(typeof item.meta === "object" && item.meta && typeof item.meta.hideMenu === "boolean" && item.meta.hideMenu)
    })
)
console.log("menus", menus)

export { constantLayoutRoutes, routes, menus, addRoute }
