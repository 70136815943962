//choiceObject.js文件
import Vue from "vue";
import ChoiceTree from "./index.vue";

const $app = Vue.extend(ChoiceTree);

const $ChoiceTree = new $app().$mount(document.createElement("div"));

document.body.appendChild($ChoiceTree.$el);

export default {
  install(vm) {
    vm.prototype.$ChoiceTree = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $ChoiceTree[key] = params[key];
        });
        console.log("ChoiceTree",params);
        $ChoiceTree.visible = true;
      },
      hide: () => {
        $ChoiceTree.visible = false;
      },
    };
  },
};
