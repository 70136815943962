//choiceObject.js文件
import Vue from "vue";
import UploadFile from "./index.vue";

const $app = Vue.extend(UploadFile);

const $UploadFile = new $app().$mount(document.createElement("div"));

document.body.appendChild($UploadFile.$el);

export default {
  install(vm) {
    vm.prototype.$UploadFile = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $UploadFile[key] = params[key];
        });
        $UploadFile.visible = true;
      },
      hide: () => {
        $UploadFile.visible = false;
      },
    };
  },
};
