/** 委托 路由 */
import productYunEntrustViewRoutes from "./product-yun-entrust-view"

/** 资源 路由 */
import productYunResourceViewRoutes from "./product-yun-resource-view"
/** 自定义表单 路由 */
// import productCustomFormViewRoutes from './product-custom-form-view';
import productCustomReportViewRoutes from "./product-yun-report-view"
import productCustomSampleViewRoutes from "./product-yun-sample-view"

const routes = [
    ...productYunEntrustViewRoutes,
    ...productCustomSampleViewRoutes,
    // ...productCustomFormViewRoutes,
    ...productCustomReportViewRoutes,
    ...productYunResourceViewRoutes,
]

export default routes
