import Vue from "vue"

import productYunTaskView from "product-yun-task-view";
// import productYunTaskView from "../../../../product-yun-task-view";
Vue.use(productYunTaskView);

import { TaskHome, TaskHomeDetails, TaskProcess } from "product-yun-task-view";
// import { TaskHome, TaskHomeDetails, TaskProcess } from "../../../../product-yun-task-view";

/** 资源 */
const routes = [
    // 实验室资质
    {
        path: "/TaskHome",
        name: "TaskHome",
        component: TaskHome,
        meta: {
            title: "检测任务",
            groupName: "任务",
            icon: "TaskHome",
        },
    },
    {
        path: "/TaskHomeDetails",
        name: "TaskHomeDetails",
        component: TaskHomeDetails,
        meta: {
            title: "我的任务",
            groupName: "任务",
            icon: "TaskProcess",
        },
    },
    {
        path: "/TaskProcess",
        name: "TaskProcess",
        component: TaskProcess,
        meta: {
            title: "任务进度跟踪",
            groupName: "任务",
            icon: "TaskProcess",
        },
    },
]

export default routes
