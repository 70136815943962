import axios from "axios"
import { Message, MessageBox } from "element-ui"
import store from "@/store"
import router from "@/router"
import { getLocalToken, removeTokenAndRedirect } from "@/core/token"
const service = axios.create({
    // baseURL: ssoServer,
    timeout: 30 * 1000,
})
// 请求拦截器
service.interceptors.request.use(
    config => {
        if (config.withQueryToken) {
            config.params = {
                ...(config.params || {}),
                "X-Auth-Token": `labbol ${getLocalToken()}`,
            }
        }
        // console.log('request.use-config', config);
        config.headers = {
            // 'Content-Type': 'application/json', //配置请求头  axios会自动设置
            Accept: "application/json",
            "X-Auth-Token": `labbol ${getLocalToken()}`,
            tenantId: store.state.userInfo.tenantId,
            productCode: "YunBusiness",
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
let showLogoutDialog = false
/** 在某一段时间内 status不为200的默认错误提示是否已经显示 用在response.use的error中 */
let showDefaultErrorMessage = false
// 3.响应拦截器
service.interceptors.response.use(
    async response => {
        // 响应拦截  成功提示/异常提示
        // showSuccessMessage显示成功信息   默认不显示 为true提示   successMessage自定义成功信息  优先级高于后端返回的信息
        // hideErrorMessage 隐藏错误信息 默认不隐藏 为true隐藏   errorMessage自定义异常信息   优先级高于后端返回的信息
        // hideLogoutDialog token过期时，默认调出提示dialog,但对于某些接口不需要调出，需要隐藏的，例如退出接口
        let { showSuccessMessage, hideErrorMessage, successMessage, errorMessage, hideLogoutDialog } = response.config
        let showSuccess = typeof showSuccessMessage === "boolean" && showSuccessMessage === true //为true  显示成功信息
        let hideError = typeof hideErrorMessage === "boolean" && hideErrorMessage === true //为true 隐藏错误信息
        let return_code
        let return_codeType
        let return_msg
        let return_msg_detail
        let errorDetail
        let errorNum = 0
        let successNum = 0
        let messageType = "success"
        let error_msg
        // 异常和成功字段有两种
        // 第一种
        let data=response.data
        if (response.data instanceof Blob) {
            console.log("response.data instanceof Blob", response.data)
            let data = await new Promise(resolve => {
                let fileReader = new FileReader()
                fileReader.onload = event => {
                    try {
                        resolve(JSON.parse(event.target.result))
                    } catch (error) {
                        // console.log('fileReader文件.catch', error);
                        resolve({})
                    }
                }
                fileReader.readAsText(response.data)
            })
        }
        return_code = data.return_code
        return_codeType = data.return_codeType
        return_msg = data.return_msg
        return_msg_detail = data.return_msg_detail
        if (typeof data.data === "object" && data.data) {
            errorDetail = data.data.errorDetail
            errorNum = data.data.errorNum
            successNum = data.data.successNum
        }
        if (return_codeType === "normal") {
            messageType = "success"
        } else if (return_codeType === "warn") {
            messageType = "warning"
        } else if (return_codeType === "error") {
            messageType = "error"
        }
        // console.log(return_msg);
        // console.log('return_code', return_code);
        // return_code === 'LAB-0000000' 成功
        if (/^lab-0+$/i.test(return_code)) {
            // console.log('^lab-0', errorNum, errorDetail);
            if (typeof errorNum === "number" && errorNum && typeof errorDetail === "string" && errorDetail) {
                Message({
                    type: "warning",
                    message: errorDetail,
                })
            } else if (showSuccess && messageType) {
                Message({
                    type: messageType,
                    message: return_msg || successMessage || "操作成功。",
                })
            }
            return response
        }
        // console.log(return_msg);
        // console.log('return_code', return_code);
        // return_code === 'LAB-0000000' 成功
        if (/^lab-0+$/i.test(return_code)) {
            // console.log('^lab-0', errorNum, errorDetail);
            if (typeof errorNum === "number" && errorNum && typeof errorDetail === "string" && errorDetail) {
                Message({
                    type: "warning",
                    message: errorDetail,
                })
            } else if (showSuccess && messageType) {
                Message({
                    type: messageType,
                    message: successMessage || return_msg || "操作成功。",
                })
            }
            return response
        }
        // return_code === 'LAB-00000007' 令牌失效 重新登录
        else if (return_code === "LAB-00000007" || error_msg === "无效的令牌") {
            if (!showLogoutDialog) {
                showLogoutDialog = true
                removeTokenAndRedirect()
                MessageBox.alert("系统空闲时间过长，请重新登录！", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: false,
                    customClass: "labMessageClass",
                })
                    .then(() => {
                        setTimeout(() => {
                            showLogoutDialog = false
                        }, 2000)
                    })
                    .catch(() => {
                        showLogoutDialog = false
                    })
            }
            throw response
        } else if (return_msg || error_msg) {
            if (!showDefaultErrorMessage && !hideError) {
                showDefaultErrorMessage = true
                Message({
                    type: "error",
                    message: return_msg || error_msg,
                })
                setTimeout(() => {
                    showDefaultErrorMessage = false
                }, 1000)
            }
            throw response
        }
        return response
    },
    async error => {
        /** 后端报错 浏览器返回response */
        let response
        /** 后端报错 浏览器返回response.status状态码 */
        let status
        /** 后端报错 返回response.data */
        let data
        /** 后端报错 返回data中接口path */
        let path
        /** 后端报错 返回data中message */
        let message
        /** 后端报错 浏览器返回请求的config */
        let config = {}
        if (error && error.response) {
            response = error.response
        }
        if (response) {
            data = response.data
            status = response.status
        }
        if (status) {
            // 1.公共错误处理
            // 2.根据响应码具体处理
            switch (status) {
                case 400:
                    error.message = "错误请求"
                    break
                case 401:
                    error.message = "未授权，请重新登录"
                    break
                case 403:
                    error.message = "拒绝访问"
                    break
                case 404:
                    error.message = "请求错误,未找到该资源"
                    // window.location.href = "/NotFound"
                    break
                case 405:
                    error.message = "请求方法未允许"
                    break
                case 408:
                    error.message = "请求超时"
                    break
                case 500:
                    error.message = "服务器端出错"
                    break
                case 501:
                    error.message = "网络未实现"
                    break
                case 502:
                    error.message = "网络错误"
                    break
                case 503:
                    error.message = "服务不可用"
                    break
                case 504:
                    error.message = "网络超时"
                    break
                case 505:
                    error.message = "http版本不支持该请求"
                    break
                default:
                    error.message = `连接错误${error.response.status}`
            }
            if (!showDefaultErrorMessage) {
                Message({
                    type: "error",
                    message: error.message,
                })
                setTimeout(() => {
                    showDefaultErrorMessage = false
                }, 4000)
            }

            throw response || error
        }
        if (typeof data === "object") {
            path = data.path
            message = data.message
        }
        if (response && typeof response.config === "object") {
            config = response.config
        }

        if (!showDefaultErrorMessage && message) {
            MessageBox.alert(message, "提示", {
                confirmButtonText: "确定",
                type: "warning",
                showClose: true,
                customClass: "labMessageClass",
            })
                .then(() => {})
                .catch(() => {})

            setTimeout(() => {
                showDefaultErrorMessage = false
            }, 8000)
        }
        Promise.reject(error.response || error)
    }
)
//4.导入文件
export default service
