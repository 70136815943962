/** 移动手机号码正则 */
const MOBILE = /^1[3-9]\d{9}$/;

/** 身份证号码正则 */
const IDCARD = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

/** 电子邮箱正则 */
const EMAIL = /^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,6})$/;

/** 固定电话正则 */
const FIXEDPHONE = /^0\d{2,3}-\d{7,8}$/;

/** 传真正则 */
const FAX = /^(\d{3,4}-)?\d{7,8}$/;

/** 港澳台正则 */
const HKCARD = /^([A-Z]\d{6,10}(\(\w{1}\))?)$/;

/** 台湾正则 */
const TWCARD= /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/;

/** 护照正则 */
const PASSCARD= /^([a-zA-z]|[0-9]){5,17}$/;

/** 军官证正则 */
const OFFIVERCARD= /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/;

/** 户口本正则 */
const ACCOUNTCARD= /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

const PATTERNS = {
	/** 移动手机号码正则 */
	MOBILE,
	/** 身份证号码正则 */
	IDCARD,
	/** 电子邮箱正则 */
	EMAIL,
	/** 固定电话正则 */
	FIXEDPHONE,
	/** 传真正则 */
	FAX,
	/** 护照 */
	PASSCARD,
	/** 军官证 */
	OFFIVERCARD,
}

const PATTERNSENUM = {
	/** 移动手机号码 */
	MOBILE: 'MOBILE',
	/** 身份证号码 */
	IDCARD: 'IDCARD',
	/** 电子邮箱 */
	EMAIL: 'EMAIL',
	/** 固定电话 */
	FIXEDPHONE: 'FIXEDPHONE',
	/** 传真 */
	FAX: 'FAX',
	/** 护照 */
	PASSCARD: 'PASSCARD',
	/** 军官证 */
	OFFIVERCARD: 'OFFIVERCARD',
}

export default PATTERNS;

export {
	/** 正则键名枚举 */
	PATTERNSENUM,
	/** 正则枚举 */
	PATTERNS,
}

/**
 * @typedef {'MOBILE'|'IDCARD'|'EMAIL'|'FIXEDPHONE'|'FAX'|'PASSCARD'|'OFFIVERCARD'} ExtendType 扩展type，统一做正则配置
*/