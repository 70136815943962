<template>
    <div id="UploadFile">
        <el-dialog class="choiceUploadFile" :title="title" width="800px" :visible.sync="visible" :modal-append-to-body="false" @close="handleClose" :show-close="false">
            <div class="bodyD">
                <div class="leftB">
                    <div class="uploadBtn" @click="DownloadTemplate">
                        <div><i class="el-icon-download"></i></div>
                        <span class="gre">下载模板</span>
                    </div>
                </div>
                <div class="rightB">
                    <el-upload drag class="uploadB" action="/" :limit="1" :multiple="false" :http-request="httpUpload" :file-list="fileList" :accept="accept" :before-upload="beforeUploadExcel">
                        <div class="uploadBtn">
                            <div><components is="Upl" /></div>
                            <span>将文件拖到此处，或点击上传</span>
                            <span style="font-size: 12px">只能上传{{ accept }}文件</span>
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="footer">
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Button, Dialog, Upload } from "element-ui"
import Upl from "./upl.svg?online"
import {getLocalToken} from "@/core/token"
import {  downloadFile} from '@/utils/utils';
export default {
    name: "choiceObject",
    components: {
        "el-button": Button,
        "el-dialog": Dialog,
        "el-upload": Upload,
        Upl,
    },
    data() {
        return {
            title: "文件上传",
            visible: false,
            file: null,
            fileList: [],
            url: "",
            size: "100",
            accept: ".xls",
            fileName: "",
            requireData: {},
        }
    },
    watch: {
        visible(val) {
            if (!val) {
                this.file = null
                this.url = ""
                this.fileName = ""
                this.fileList = []
                this.requireData = {}
            }
        },
    },
    methods: {
        beforeUploadExcel(file) {
            const fileSuffix = "." + file.name.substring(file.name.lastIndexOf(".") + 1)

            const whiteList = this.accept.split(",")
            if (whiteList.indexOf(fileSuffix.toLowerCase()) === -1) {
                this.$message.warning(`文件只能是${whiteList.join("、")}格式`)

                return false
            }

            let imgSize = Number(file.size / 1024 / 1024)
            if (imgSize > this.size) {
                this.$message.warning(`文件大小不能超过${this.size}MB，请重新上传`)
                return false
            }
        },
        handleSubmit() {
            if (typeof this.successCallback === "function") {
                this.successCallback(this.file)
            } 
            this.visible = false
        },
        handleClose() {
            this.visible = false
        },
        httpUpload(params) {
            this.file = params.file
        },
        DownloadTemplate() {
            if (typeof this.downFile === "function") {
                this.downFile()
            } else {
                this.api
                    .postFormAPI(
                        [],
                        this.url?this.url:"v1.0/resource/resource/download",
                        {
                           
                            "X-Auth-Token": `labbol ${getLocalToken()}`,
                           ...this.requireData,
                        },
                        {
                            responseType: "blob",
                        }
                    )
                    .then(res => {
                        downloadFile(this.fileName, res.data)
                    })
                    .catch(error => {
                        console.log("error", error)
                    })
            }
        },
    },
}
</script>
<style lang="less">
#UploadFile {
    .el-dialog__body {
        padding: 10px 20px;
    }
    & > .choiceUploadFile {
        .bodyD {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 10px;
            height: 270px;
            padding: 0 0 30px;
            .leftB {
                border: 1px dashed #ddd;
                height: 100%;
                border-radius: 5px;
                &:hover {
                    border: 1px dashed #3370ff;
                }
                .gre {
                    background: #00a656;
                    padding: 4px 16px;
                    border-radius: 5px;
                    color: #fff;
                }
            }
            .rightB {
                height: 100%;
                border-radius: 5px;
            }

            .uploadBtn,
            .el-upload {
                width: 100%;
                height: 100%;
            }
            .rightB {
                display: flex;
                justify-content: center;
                align-items: center;
                .uploadB {
                    height: 100%;
                    .el-upload-dragger {
                        height: 100%;
                    }
                }
            }
            .uploadBtn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                div {
                    i {
                        color: #ddd;
                        font-size: 90px;
                    }
                    svg {
                        color: #ddd;
                        width: 90px;
                        height: 90px;
                    }
                    span {
                    }
                }
            }
        }
    }
    .footer {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
