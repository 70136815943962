<template>
    <div id="my-upload">
        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[images]" />
        <el-upload
            action="/"
            :class="disabled ? 'disable_button' : ''"
            :multiple="multiple"
            :disabled="disabled"
            :limit="limit"
            :http-request="file => httpUpload(file)"
            :on-exceed="file => onExceed(file)"
            :file-list="fileList"
            :show-file-list="false"
            :accept="accept"
            :before-upload="beforeUpload"
        >
            <el-button class="able_button" size="small">点击上传</el-button>
        </el-upload>
        <div class="imgList" v-if="fileList.length || !load">
            <div class="img_item" v-for="(item, index) in fileList" :key="item.url">
                <p @click="handleFileClick(item)">
                    <span><svg-icon :style="{ fontSize: '16px' }" icon-class="picture"></svg-icon></span>
                    {{ item.name }}
                </p>
                <el-button v-if="!disabled" size="small" type="text" @click="removeFile(index, item)">删除</el-button>
            </div>
        </div>
        <div v-if="load" class="imggif">
            <img src="./load.gif" alt="" />
        </div>
        <div v-if="disabled && fileList.length == 0" class="imgList">
            <div class="img_item">
                <p>暂无数据</p>
            </div>
        </div>
    </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer"
import { previewReport, getFileUrl } from "@/api/main"
import { MessageBox } from "element-ui"
export default {
    name: "MyUpload",
    components: { ElImageViewer },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        download: {
            //下载
            type: Function,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        fileList: {
            type: Array,
            default: [],
        },
        limit: {
            type: Number,
            default: 1,
        },
        size: {
            type: Number,
            default: 10,
        },
        accept: {
            type: String,
            default: ".pdf,.png,.jpg,.jpeg,.bmp",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showViewer: false,
            images: [],
            url: "",
            load: false,
        }
    },
    methods: {
        //列表图片点击
        async handleFileClick(item) {
            const fileSuffix = item.name.substring(item.name.lastIndexOf(".") + 1)
            if (this.preview && fileSuffix != "pdf") {
                const { data: url } = (await this.api.getListAPI({ fileName: item.url },"/v1.0/resource/file/getFileUrl")) || ""
                if (this.download) {
                    this.download(item.name, url)
                } else {
                    this.images = [url]
                    this.onPreview()
                }
            } else {
                if (item.url) {
                    // console.log(item.url);
                    window.open(item.url)
                    //中文编码处理
                    // let baseStr = btoa(String.fromCharCode(...new TextEncoder().encode(item.url)))
                    // previewReport({
                    //     fileUrl: baseStr,
                    //     fileName: item.name,
                    // }).then(res => {
                    //     this.$SetFile.show({
                    //         url: res.data,
                    //         isOriginal: true,
                    //         successCallback: e => {},
                    //     })
                    // })
                }
            }
        },
        Download(filename, url) {
            const link = document.createElement("a")
            link.style.display = "none"
            link.download = filename //跨域文件名无效
            link.href = url
            console.log(filename)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
        removeFile(index, item) {
            this.$emit("remove", index, item, this.fileList)
        },
        httpUpload(file) {
            this.$emit("upload", file)
        },
        //文件超出限制
        onExceed(file) {
            let File = { file: file[0] }
            this.httpUpload(File)
        },
        //大图
        onPreview() {
            this.showViewer = true
        },
        // 关闭查看器
        closeViewer() {
            this.images = []
            this.showViewer = false
        },
        beforeUpload(file) {
            const fileSuffix = "." + file.name.substring(file.name.lastIndexOf(".") + 1)

            const whiteList = this.accept.split(",")
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message.warning(`文件只能是${whiteList.join("、")}格式`);
                // MessageBox.alert(`文件只能是${whiteList.join("、")}格式`, "提示", {
                //     confirmButtonText: "确定",
                //     type: "warning",
                //     showClose: true,
                //     customClass: "labMessageClass",
                // })
                return false
            }

            let imgSize = Number(file.size / 1024 / 1024)
            if (imgSize > this.size) {
                this.$message.warning(`文件大小不能超过${this.size}MB，请重新上传`);
                // MessageBox.alert(`文件大小不能超过${this.size}MB，请重新上传。`, "提示", {
                //     confirmButtonText: "确定",
                //     type: "warning",
                //     showClose: true,
                //     customClass: "labMessageClass",
                // })
                return false
            }
            this.$emit("beforeUpload", file)
        },
    },
    watch: {
        loading(val) {
            this.load = val
            //根据请求时间超时 timeout 超时加载失败
            if (val) {
                setTimeout(() => {
                    this.load = false
                }, 30 * 1000)
            }
        },
    },
}
</script>

<style lang="less">
#my-upload {
    .able_button {
        background-color: #2d74f4;
        color: white;
    }
    .disable_button .el-upload--text {
        display: none;
    }
    .imgList {
        .img_item {
            &:hover {
                background: #f5f7fa;
            }
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                padding-left: 4px;
                font-size: 14px;
                width: calc(100% - 26px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #606266;
                span {
                    margin-right: 4px;
                }
            }
            & .el-button {
                font-size: 14px;
            }
        }
    }
    .imggif {
        height: 22px;
        text-align: center;
        img {
            height: 100%;
        }
    }
}
.el-image-viewer__wrapper {
    z-index: 3000 !important;
    position: absolute;
    .el-image-viewer__canvas {
        position: absolute;
        height: 80%;
        top: 10%;
    }
}
</style>
