import api from "./api";
// 以下为模板库接口
/** 获取列表数据 */
const querySimpleTemplate = (params) => api.postFormAPITemplate(params, 'templateV2/querySimpleTemplate')

/** 获取统计数据 */
const getSimpleTemplateData = (params) => api.getListAPITemplate(params, 'templateV2/getSimpleTemplateData');

/** 新建 */
const saveSimpleTemplate = (params) => api.postFormAPITemplate(params, 'templateV2/saveSimpleTemplate', '', { showSuccessMessage: true });

/**
 * @param { { simpleTemplateId: String } } params
 * @description 获取模板库台账
 * */
const getSimpleTemplate = (params) => api.getListAPITemplate(params, 'templateV2/getSimpleTemplate');

/** 修改 */
const modifySimpleTemplate = (params) => api.postFormAPITemplate(params, 'templateV2/modifySimpleTemplate', '', { showSuccessMessage: true });

/** 删除 */
const deleteSimpleTemplate = (params) => api.postFormAPITemplate(params, 'templateV2/deleteSimpleTemplate', '', { showSuccessMessage: true });

/** 上传模板 */
const uploadSimpleTemplate = (params) => api.postFormAPITemplate(params, 'templateV2/uploadSimpleTemplate', '', { showSuccessMessage: true });

/** 更改状态 */
const updateSimpleTemplateStatus = (params, config) => api.postFormAPITemplate(params, 'templateV2/updateSimpleTemplateStatus', '', { showSuccessMessage: true, ...config || {} });

/** 查询模板库分类 */
const querySimpleTemplateSampleClass = (params) => api.getListAPITemplate(params, 'templateV2/querySimpleTemplateSampleClass');

/** 从Excel导入 */
const importSimpleTemplate = (params) => api.postFormAPITemplate(params, 'templateV2/importSimpleTemplate', '', { showSuccessMessage: true });

/** 保存报告设计组件 文档模板设计 */
const saveReportComponentSetup = (params) => api.postFormAPITemplate(params, 'format/saveReportComponentSetup');

// /** 查询报告设计组件 文档模板设计 */
const queryDetailsReportComponentSetup = (params) => api.getListAPITemplate(params, 'format/queryDetailsReportComponentSetup');

/** 查询报告设计组件 文档模板设计 */
const queryPageReportComponentSetup = (params) => api.postFormAPITemplate(params, 'format/queryPageReportComponentSetup');

/** 报告格式组件配置关联模板 */
const reportComponentRelationTemplate = (params) => api.postFormAPITemplate(params, 'format/reportComponentRelationTemplate', '', { showSuccessMessage: true });

/** 创建或修改报告格式组件配置 */
const saveOrModifyReportComponentSetup = (params) => api.postFormAPITemplate(params, 'format/saveOrModifyReportComponentSetup', '', { showSuccessMessage: true });

/**
 * @param { Object } params query参数
 * @param {String} params.compSetupId 页面id
 * @description 删除报告格式组件配置
*/
const delReportComponentSetup = (params) => api.getListAPITemplate(params, '/format/delReportComponentSetup', { showSuccessMessage: true });

/**
 * @param { Object } params query参数
 * @param {String} params.sectionId 章节id
 * @description 删除章节
*/
const delReportComponentSetupSection = (params) => api.getListAPITemplate(params, '/format/delReportComponentSetupSection', { showSuccessMessage: true });

const queryDataset =(params) => api.postFormAPITemplate(params,'/format/queryDataset')

const insertOrUpdateComplexTemplate = (params) => api.postFormAPITemplate(params, 'template/insertOrUpdateComplexTemplate')

export {
	querySimpleTemplate,
	getSimpleTemplateData,
	saveSimpleTemplate,
	getSimpleTemplate,
	modifySimpleTemplate,
	deleteSimpleTemplate,
	uploadSimpleTemplate,
	updateSimpleTemplateStatus,
	querySimpleTemplateSampleClass,
	importSimpleTemplate,
	saveReportComponentSetup,
	queryDetailsReportComponentSetup,
	queryPageReportComponentSetup,
	reportComponentRelationTemplate,
	saveOrModifyReportComponentSetup,
	delReportComponentSetup,
	delReportComponentSetupSection,
	queryDataset,
	insertOrUpdateComplexTemplate,
}