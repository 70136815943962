//choicePer.js文件
import Vue from "vue";
import choicePer from "./index.vue";

const $app = Vue.extend(choicePer);

const $choicePer = new $app().$mount(document.createElement("div"));

document.body.appendChild($choicePer.$el);

export default {
  install(vm) {
    vm.prototype.$choicePer = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $choicePer[key] = params[key];
        });
        $choicePer.visible = true;
      },
      hide: () => {
        $choicePer.visible = false;
      },
    };
  },
};
